// トップスライダーの起動 // Launch the top slider
// MV Top slide
const topSwiper = new Swiper(".js-topSwiper", {
  speed: 9000, // スライド変化時間
  loop: true,
  loopAdditionalSlides: 1,
  slidesPerView: 3,
  spaceBetween: 24,
  centeredSlides: true,
  autoplay: {
    delay: 0,
    disableOnInteraction: false,
  },
  breakpoints: {
    375: {
      slidesPerView: 2,
      spaceBetween: 10,
    },
  },
});

const galleryswiper = new Swiper(".js-gallery-swiper", {
    slidesPerView: "auto",
    centeredSlides: true,
    loop: true,
    loopAdditionalSlides: 1,
    speed: 6500, 
    allowTouchMove: false,
    autoplay: {
     delay: 0,
    },
});


$(document).ready(function() {
    // Initialize Swiper for each case
    $('.js-slideCase').each(function(index, element) {
        new Swiper(element, {
            speed: 1000,
            loop: true,
            slidesPerView: 3, // Adjust slides per view to handle partial visibility
            centeredSlides: true,
            spaceBetween: 10,
            autoplay: {
                delay: 4000,
                disableOnInteraction: false,
            },
            navigation: {
                nextEl: $(element).find('.swiper-button-next')[0],
                prevEl: $(element).find('.swiper-button-prev')[0],
            },
            breakpoints: {
                375: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                767: {
                    slidesPerView: 3,
                }
            },
            // on: {
            //     slideChangeTransitionStart: function() {
            //         updateSlideWidths(element);
            //     },
            //     resize: function() {
            //         updateSlideWidths(element);
            //     }
            // }
        });
        
        // Initial call to set slide widths
        // updateSlideWidths(element);
    });
});

function updateSlideWidths(swiperContainer) {
    const swiperWrapper = swiperContainer.querySelector('.swiper-wrapper');
    const slides = swiperWrapper.querySelectorAll('.swiper-slide');
    const swiperContainerWidth = swiperContainer.clientWidth;
    const halfWidth = swiperContainerWidth / 2;
    const fullWidth = swiperContainerWidth;

    slides.forEach(slide => {
        const slideContent = slide.firstElementChild;
        if (slide.classList.contains('swiper-slide-active')) {
            slideContent.style.width = `${fullWidth}px`;
        } else {
            slideContent.style.width = `${halfWidth}px`;
        }
    });
}


$(document).ready(function() {
    let swiperInstance = null;

    function handleSwiper() {
        if ($(window).width() < 767) {
            if (!swiperInstance) {
                swiperInstance = new Swiper('.js-slideServiceMobile', {
                    slidesPerView: 1.2,
                    spaceBetween: 10,
                    loop: true,
                    autoplay: {
                        delay: 3000,
                    },
                });
            }
        } else {
            if (swiperInstance) {
                swiperInstance.destroy(true, true);
                swiperInstance = null;
            }
        }
    }

    handleSwiper(); // Initial check
    $(window).resize(handleSwiper); // Check on resize
});
